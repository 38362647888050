import {
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgPayment,
  OrgSeasonId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { isParentOrgInvoice } from "../../utils";

export async function orgRegistration__server__getOrgRegistrationDataForDashboard(p: {
  orgId: OrgId;
  orgSeasonId: OrgSeasonId;
}): Promise<{
  noRegistrationNeeded: number;
  registered: number;
  unregistered: number;
  incomplete: number;
  bad: number;
}> {
  const { getAppPgPool } = getServerHelpers();

  const query = `select sum(
    case when status = 'no-registration-needed' then 1 else 0 end
) as no_registration_needed,
sum(
    case when status = 'registered' then 1 else 0 end
) as registered,
sum(
    case when status = 'team-not-ready' then 1 else 0 end
) as team_not_ready,
sum(
  case when status = 'incomplete' then 1 else 0 end
) as incomplete,
sum(
    case when status = 'unregistered' then 1 else 0 end
) as unregistered,
sum(
    case when status = 'bad' then 1 else 0 end
) as bad
from f_player_bundle_registration_status($1, null)
where org_season_id = $2
group by org_id, org_season_id;`;

  const r1 = await getAppPgPool().query(query, [p.orgId, p.orgSeasonId]);

  if (r1.rows.length) {
    return {
      bad: r1.rows[0]["bad"],
      registered: r1.rows[0]["registered"],
      incomplete: r1.rows[0]["incomplete"],
      noRegistrationNeeded: r1.rows[0]["no_registration_needed"],
      unregistered: r1.rows[0]["unregistered"]
    };
  }
  {
    return {
      bad: 0,
      registered: 0,
      incomplete: 0,
      noRegistrationNeeded: 0,
      unregistered: 0
    };
  }
}

orgRegistration__server__getOrgRegistrationDataForDashboard.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
