import {
  AccountId,
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgPayment,
  OrgRegistration,
  OrgRegistrationPackage,
  OrgRegistrationPaymentStatus,
  OrgRegistrationStatus,
  OrgSeason,
  OrgSeasonId,
  PlayerBundleId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { OrgRegistrationInfo } from "../../constants/Misc";
import { all } from "async";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";

export async function orgRegistration__server__getOrgRegistrationDataForAccountId(p: {
  selfAccountId: AccountId;
}): Promise<OrgRegistrationInfo[]> {
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();

  const { getAppPgPool } = getServerHelpers();

  const managedPlayerBundles = (await h.PlayerBundle.query(getManagedPlayerBundlesForAccountIdFragment(p.selfAccountId))).docs;

  if (!managedPlayerBundles.length) {
    return [];
  }

  const query = `select v.*, os.item as org_season, rp.item as org_package
  from f_player_bundle_registration_status(null, $1) v,
  mirror_orgseason os,
  mirror_orgregistrationpackage rp
  where os.id = v.org_season_id
  and v.prioritized_registration_package_id = rp.id
  and v.status <> 'no-registration-needed'`;

  const r1 = await getAppPgPool().query(query, [managedPlayerBundles.map(mpb => mpb.id)]);

  return r1.rows.map(row => {
    return {
      orgSeason: row["org_season"] as OrgSeason,
      playerBundleId: row["player_bundle_id"] as PlayerBundleId,
      orgId: row["org_id"] as OrgId,
      prioritizedRegistrationPackage: row["org_package"] as OrgRegistrationPackage,
      invoicePaymentMSDates: _.orderBy(JSON.parse(row["invoice_payment_ms_dates_json_array"]), a => a, "asc") as number[],
      //Only has auto charge dates that HAVE NOT been paid yet
      pendingAutoChargeMSDates: _.orderBy(JSON.parse(row["pending_auto_charge_ms_dates_json_array"]), a => a, "asc") as number[],
      status: row["status"] as
        | OrgRegistrationStatus.bad
        | OrgRegistrationStatus.registered
        | OrgRegistrationStatus.unregistered
        | OrgRegistrationStatus.incomplete,
      paymentStatus: row["payment_status"] as OrgRegistrationPaymentStatus,
      orgRegistration: (row["org_registration"] ?? undefined) as OrgRegistration | undefined
    };
  });
}

orgRegistration__server__getOrgRegistrationDataForAccountId.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
