import {
  OrgId,
  OrgRegistrationPackageId,
  OrgRegistrationStatus,
  OrgSeasonId,
  PlayerBundleId,
  TeamId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function orgRegistration__server__getOrgRegistrationDataForListOfPlayerBundleIdsInOrgSeasons(p: {
  playerBundleIds: PlayerBundleId[];
  orgId: OrgId;
  orgSeasonIds: OrgSeasonId[];
  teamId: TeamId;
}): Promise<
  Record<
    OrgSeasonId,
    {
      teamAssignedRegistrationPackageId?: string | false;
      playerData: {
        playerBundleId: any;
        status: OrgRegistrationStatus;
      }[];
    }
  >
> {
  const { getAppPgPool } = getServerHelpers();

  const query = `select player_bundle_id, status, org_season_id
  from f_player_bundle_registration_status($3, $2::text[])
  where org_season_id = any ($1::text[]);`;

  const query2 = `select org_registration_package_id, org_season_id
  from f_team_assigned_registration_package_by_season_id($3::text[])
    where org_season_id = any ($1::text[])
    and team_id = $2;`;

  const [r1, r2] = await Promise.all([
    getAppPgPool().query(query, [p.orgSeasonIds, p.playerBundleIds, p.orgId]),
    getAppPgPool().query(query2, [p.orgSeasonIds, p.teamId, [p.orgId]])
  ]);

  const allPlayerData = r1.rows.map(row => {
    return {
      playerBundleId: row["player_bundle_id"] as PlayerBundleId,
      status: row["status"] as OrgRegistrationStatus,
      orgSeasonId: row["org_season_id"] as OrgSeasonId
    };
  });
  const allPackages = r2.rows.map(row => {
    return {
      orgRegistrationPackageId: row["org_registration_package_id"] as OrgRegistrationPackageId,
      orgSeasonId: row["org_season_id"] as OrgSeasonId
    };
  });

  return p.orgSeasonIds.reduce(
    (acc, orgSeasonId) => {
      const orgRegistrationPackageData = allPackages.find(a => a.orgSeasonId === orgSeasonId);
      const teamAssignedRegistrationPackageId = !orgRegistrationPackageData
        ? undefined
        : orgRegistrationPackageData?.orgRegistrationPackageId === "false"
        ? false
        : (orgRegistrationPackageData.orgRegistrationPackageId as OrgRegistrationPackageId);
      acc[orgSeasonId] = {
        teamAssignedRegistrationPackageId,
        playerData: allPlayerData.filter(a => a.orgSeasonId === orgSeasonId)
      };
      return acc;
    },
    {} as Record<
      OrgSeasonId,
      {
        teamAssignedRegistrationPackageId?: string | false;
        playerData: {
          playerBundleId: any;
          status: OrgRegistrationStatus;
        }[];
      }
    >
  );
}

orgRegistration__server__getOrgRegistrationDataForListOfPlayerBundleIdsInOrgSeasons.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
