import { OrgId, OrgInvoiceId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { OrgRegistrationInfo } from "../../constants/Misc";

export async function orgRegistration__client__getOrgRegistrationForOrgInvoice(p: {
  orgRegistrationInvoiceId: OrgInvoiceId;
  orgId: OrgId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const r = (
    await h.OrgRegistration.query({
      where: [
        {
          orgInvoiceId: ["==", p.orgRegistrationInvoiceId]
        },
        {
          orgId: ["==", p.orgId]
        }
      ],
      limit: 1
    })
  ).docs;

  return r.length ? r[0] : undefined;
}

// i18n certified - complete
