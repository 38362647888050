import { OrgId, OrgRegistrationStatus, OrgRegistrationTableData, OrgSeasonId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { ObjectKeys, isParentOrgInvoice } from "../../utils";

type SortKeys = "status" | "name" | "packageName" | "packageAmountCents" | "registrationDateMS" | "registrationLink";
const sortKeys: Record<SortKeys, true> = {
  status: true,
  name: true,
  packageName: true,
  packageAmountCents: true,
  registrationDateMS: true,
  registrationLink: true
};

export async function orgRegistration__server__getRegistrationDataForDashboardTable(p: {
  orgId: OrgId;
  selfAccountId: string;
  orgSeasonId: OrgSeasonId;
  offset?: number;
  limit?: number;
  searchTerm?: string;
  status?: OrgRegistrationStatus;
  orgTeamTagIds?: Record<string, true>;
  sort?: {
    key: SortKeys;
    dir: "desc" | "asc";
  }[];
}) {
  const { getAppPgPool } = getServerHelpers();

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    orgSeasonId: { valid: true, val: p.orgSeasonId },
    offset: { valid: true, val: p.offset || 0 },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    limit: { valid: true, val: p.limit || 100 },
    status: { valid: !!p.status, val: p.status ?? OrgRegistrationStatus.registered }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const validFields = ObjectKeys(sortKeys);

  const validDirections = ["asc", "desc"];
  let sort = (p.sort || [])
    .filter(a => validFields.includes(a.key) && validDirections.includes(a.dir))
    .map(a => ({ ...a, key: _.snakeCase(a.key) }));

  if (sort.length === 0) {
    sort.push({ key: "name", dir: "asc" });
  }

  const orderBy = sort
    .map(a => {
      if (a.key === "name") {
        return `trim(concat(first_name, ' ', last_name)) ` + a.dir;
      } else {
        return `${a.key} ${a.dir}`;
      }
    })
    .join(", ");

  const orgTeamTagFilterText = Object.keys(p.orgTeamTagIds ?? {}).length
    ? ` where ${Object.keys(p.orgTeamTagIds ?? {})
        .map(a => {
          return `orp.item -> 'orgTeamTagIds' ->> '${a}' = 'true'`;
        })
        .join(" or ")}`
    : "";

  const query = `
select * from
(select a.*, orp.item -> 'name' as package_name, orp.item -> 'amountCents' as package_amount_cents,
count(*) OVER () AS count
from (
  select  v.org_registration-> 'createdAtMS' as registration_date_ms,
          v.prioritized_registration_package_id,
          v.status,
          v2.player_bundle_id,
          v2.first_name,
          v2.last_name,
          v2.self_athlete_account_id,
          v.org_registration
  from f_player_bundle_registration_status(${queryParams.orgId!.sqlParam}, null) v,
       f_player_bundles_with_name_id_and_self_athlete_account_id(${queryParams.orgId!.sqlParam}, null) v2
  where v2.player_bundle_id = v.player_bundle_id
        and v.org_season_id = ${queryParams.orgSeasonId!.sqlParam}
        ${queryParams.status ? `and v.status = ${queryParams.status.sqlParam}` : ""}
        ${
          queryParams.searchTerm
            ? `and trim(concat(v2.first_name,' ', v2.last_name)) ilike ${queryParams.searchTerm.sqlParam}`
            : ""
        }
) a
left join mirror_orgregistrationpackage orp on orp.id = a.prioritized_registration_package_id${orgTeamTagFilterText}) b
order by ${orderBy}
offset ${queryParams.offset!.sqlParam} LIMIT ${queryParams.limit!.sqlParam};`;

  const r1 = await getAppPgPool().query(
    query,
    Object.values(queryParams).map(a => a.value)
  );

  if (!r1.rowCount) {
    return {
      data: [] as OrgRegistrationTableData[],
      count: 0
    };
  } else {
    const returnData = r1.rows.map(row => {
      const data: OrgRegistrationTableData = {
        registrationDateMS: row["registration_date_ms"],
        firstName: row["first_name"],
        lastName: row["last_name"],
        playerBundleId: row["player_bundle_id"],
        status: row["status"],
        selfAthleteAccountId: row["self_athlete_account_id"],
        packageAmountCents: row["package_amount_cents"],
        packageName: row["package_name"],
        orgRegistration: row["org_registration"]
      };

      return data;
    });
    return {
      data: returnData,
      count: parseInt(r1.rows[0].count ?? 0)
    };
  }
}

orgRegistration__server__getRegistrationDataForDashboardTable.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
