import {
  AccountId,
  OrgId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgPaymentType,
  OrgRegistrationId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { BatchTask } from "@ollie-sports/firebase";
import { translate } from "@ollie-sports/i18n";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

export async function orgRegistration__server__deleteOrgRegistration(
  this: ServerThisContext,
  p: {
    orgRegistrationId: OrgRegistrationId;
    orgId: OrgId;
    selfAccountId: AccountId;
  }
) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const [orgRegistration, org] = await Promise.all([h.OrgRegistration.getDoc(p.orgRegistrationId), h.Org.getDoc(p.orgId)]);

  if (!orgRegistration) {
    throw new Error("Registration does not exist");
  }

  if (!org || !org.accounts[p.selfAccountId]?.permissions.manageFinances) {
    throw new Error("User does not have permission to delete registration");
  }

  const [invoicesData, registrationAnswersData] = await Promise.all([
    h.OrgInvoice.query({
      where: [
        {
          orgId: ["==", p.orgId]
        },
        {
          invoiceGroupId: ["==", orgRegistration.orgInvoiceId]
        }
      ]
    }),
    h.OrgRegistrationAnswer.getDocs(Object.keys(orgRegistration.answerIds ?? {}))
  ]);

  const invoices = invoicesData.docs;
  const registrationAnswers = _.compact(registrationAnswersData);

  const batchTasks: BatchTask[] = [];

  batchTasks.push(await h.OrgRegistration.delete({ id: p.orgRegistrationId }, { returnBatchTask: true }));
  for (let i = 0; i < registrationAnswers.length; i++) {
    const registrationAnswerToDelete = registrationAnswers[i];
    batchTasks.push(await h.OrgRegistrationAnswer.delete({ id: registrationAnswerToDelete.id }, { returnBatchTask: true }));
  }
  for (let j = 0; j < invoices.length; j++) {
    const orgInvoiceToModify = invoices[j];
    if (orgInvoiceToModify.type === OrgInvoiceTypes.registration) {
      const manualInvoice: OrgInvoice__Manual = {
        type: OrgInvoiceTypes.manual,
        amountDueCents: orgInvoiceToModify.amountDueCents,
        assignedByAccountId: p.selfAccountId,
        createdAtMS: orgInvoiceToModify.createdAtMS,
        derivedTotalAmountDueCentsIncludingChildrenInvoices:
          orgInvoiceToModify.derivedTotalAmountDueCentsIncludingChildrenInvoices,
        derivedTotalAmountPaidCentsBeforeAllFees: orgInvoiceToModify.derivedTotalAmountPaidCentsBeforeAllFees,
        derivedTotalAmountPaidCentsIncludingChildrenInvoices:
          orgInvoiceToModify.derivedTotalAmountPaidCentsIncludingChildrenInvoices,
        dueDateMS: orgInvoiceToModify.dueDateMS,
        id: orgInvoiceToModify.id,
        invoiceGroupId: orgInvoiceToModify.invoiceGroupId,
        lateFeeCentsToBeIssuedIfLate: orgInvoiceToModify.lateFeeCentsToBeIssuedIfLate,
        memo: this.translate({ defaultMessage: "Deleted Registration" }) + " - " + orgInvoiceToModify.memo,
        orgId: orgInvoiceToModify.orgId,
        playerBundleId: orgInvoiceToModify.playerBundleId,
        thisInvoicePaidInFullDateMS: orgInvoiceToModify.thisInvoicePaidInFullDateMS,
        appliedCouponCodeDetails: orgInvoiceToModify.appliedCouponCodeDetails
      };
      batchTasks.push(await h.OrgInvoice.set({ id: orgInvoiceToModify.id, doc: manualInvoice }, { returnBatchTask: true }));
    } else if (orgInvoiceToModify.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) {
      const manualInvoiceChild: OrgInvoice__ManualPaymentPlanInstallment = {
        type: OrgInvoiceTypes.manualPaymentPlanInstallment,
        amountDueCents: orgInvoiceToModify.amountDueCents,
        createdAtMS: orgInvoiceToModify.createdAtMS,
        derivedTotalAmountPaidCentsBeforeAllFees: orgInvoiceToModify.derivedTotalAmountPaidCentsBeforeAllFees,
        dueDateMS: orgInvoiceToModify.dueDateMS,
        id: orgInvoiceToModify.id,
        invoiceGroupId: orgInvoiceToModify.invoiceGroupId,
        lateFeeCentsToBeIssuedIfLate: orgInvoiceToModify.lateFeeCentsToBeIssuedIfLate,
        memo: orgInvoiceToModify.memo,
        orgId: orgInvoiceToModify.orgId,
        playerBundleId: orgInvoiceToModify.playerBundleId,
        thisInvoicePaidInFullDateMS: orgInvoiceToModify.thisInvoicePaidInFullDateMS,
        accountIdScheduledToPay: orgInvoiceToModify.accountIdScheduledToPay,
        autoChargeDateMS: orgInvoiceToModify.autoChargeDateMS,
        orgPaymentPlanId: orgInvoiceToModify.orgPaymentPlanId,
        parentOrgInvoiceId: orgInvoiceToModify.parentOrgInvoiceId,
        failedPaymentScheduledRetryMS: orgInvoiceToModify.failedPaymentScheduledRetryMS,
        numberOfFailedPaymentAttempts: orgInvoiceToModify.numberOfFailedPaymentAttempts,
        previousFailedPaymentAttemptMS: orgInvoiceToModify.previousFailedPaymentAttemptMS
      };
      batchTasks.push(await h.OrgInvoice.set({ id: orgInvoiceToModify.id, doc: manualInvoiceChild }, { returnBatchTask: true }));
    }
  }
  await h._BatchRunner.executeBatch(batchTasks);
}

orgRegistration__server__deleteOrgRegistration.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
