import { getServerHelpers, getUniversalHelpers } from "../../helpers";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { isProduction } from "../../utils";
import {
  IneligibilityReminderData,
  triggerForIneligibleNotifications,
  triggerForTeamAndOrgIneligibleNotifications
} from "../notification/registration-helpers";

export async function orgRegistration__server__sendTeamAndOrgIneligibleReportEmails() {
  // SERVER_ONLY_TOGGLE
  console.log("--- sendTeamAndOrgIneligibleReportEmails ---");

  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  olliePipe.emitEvent({
    type: "metric-send-team-and-org-ineligible-report",
    payload: {}
  });

  let offset = 0;
  let batchSize = 2000;
  let currentOrgId = "";
  let currentOrgPlayerBundleIds: string[] = [];

  while (true) {
    try {
      const results = (
        await getAppPgPool().query(`select f.org_id, f.player_bundle_id
        from f_player_bundle_registration_status(null, null) f,
             mirror_orgseason os
        where os.id = f.org_season_id
          and f.status != 'registered'
          and f.status != 'team-not-ready'
          and f.status != 'no-registration-needed'
          and cast(os.item ->> 'startDateMS' as numeric) < EXTRACT(EPOCH FROM NOW()) * 1000 -- season has started
          and cast(os.item ->> 'endDateMS' as numeric) > EXTRACT(EPOCH FROM NOW()) * 1000 -- season hasn't ended
          and cast(os.item ->> 'registrationDueDateMS' as numeric) <
              EXTRACT(EPOCH FROM NOW()) * 1000                                              -- registration due date has passed
          order by f.org_id asc
          limit ${batchSize} offset ${offset}`)
      ).rows.map(row => {
        return {
          orgId: row["org_id"],
          playerBundleId: row["player_bundle_id"]
        };
      });
      console.log("---- RESULTS ----");
      console.log(results);
      for (let i = 0; i < results.length; i++) {
        if (!currentOrgId) {
          currentOrgId = results[i].orgId;
          currentOrgPlayerBundleIds = [results[i].playerBundleId];
        } else if (currentOrgId === results[i].orgId) {
          currentOrgPlayerBundleIds.push(results[i].playerBundleId);
        } else {
          try {
            await triggerForTeamAndOrgIneligibleNotifications({
              orgId: currentOrgId,
              playerBundleIds: _.uniq(currentOrgPlayerBundleIds)
            });
            olliePipe.emitEvent({
              type: "metric-send-team-and-org-ineligible-report",
              payload: { type: "success", orgId: currentOrgId, playerBundleIds: _.uniq(currentOrgPlayerBundleIds) }
            });
          } catch (e) {
            olliePipe.emitEvent({
              type: "metric-send-team-and-org-ineligible-report",
              payload: { type: "error-on-org", orgId: currentOrgId, numPlayerBundleIds: _.uniq(currentOrgPlayerBundleIds).length }
            });
          }
          currentOrgId = results[i].orgId;
          currentOrgPlayerBundleIds = [results[i].playerBundleId];
        }
      }
      if (results.length !== batchSize) {
        break;
      }
      offset += batchSize;
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-team-and-org-ineligible-report",
        payload: { type: "error-on-query", orgId: currentOrgId, numPlayerBundleIds: currentOrgPlayerBundleIds.length }
      });
      break;
    }
  }

  try {
    olliePipe.emitEvent({
      type: "metric-send-team-and-org-ineligible-report",
      payload: { type: "success", orgId: currentOrgId, playerBundleIds: _.uniq(currentOrgPlayerBundleIds) }
    });
    await triggerForTeamAndOrgIneligibleNotifications({
      orgId: currentOrgId,
      playerBundleIds: _.uniq(currentOrgPlayerBundleIds)
    });
  } catch (e) {
    olliePipe.emitEvent({
      type: "metric-send-team-and-org-ineligible-report",
      payload: { type: "error-on-org", orgId: currentOrgId, numPlayerBundleIds: currentOrgPlayerBundleIds.length }
    });
  }
  // SERVER_ONLY_TOGGLE
}

orgRegistration__server__sendTeamAndOrgIneligibleReportEmails.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
