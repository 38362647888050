import { getServerHelpers, getUniversalHelpers } from "../../helpers";

import express from "express";
import _ from "lodash";
import moment from "moment";
import { isProduction } from "../../utils";
import { IneligibilityReminderData, triggerForIneligibleNotifications } from "../notification/registration-helpers";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function orgRegistration__server__sendIneligibleReminders() {
  // SERVER_ONLY_TOGGLE
  console.log("--- sendIneligibleReminders ---");

  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  olliePipe.emitEvent({
    type: "metric-send-ineligible-reminders",
    payload: {}
  });

  const numDaysArray: { type: "reminder" | "pastDue"; numDays: number }[] = [
    {
      numDays: 1,
      type: "reminder"
    },
    {
      numDays: 3,
      type: "reminder"
    },
    {
      numDays: 7,
      type: "reminder"
    },
    ...([1, 3].map(num => {
      return { numDays: num, type: "pastDue" };
    }) as { type: "reminder" | "pastDue"; numDays: number }[])
  ];

  let allIneligibleData: IneligibilityReminderData[] = [];

  try {
    for (let i = 0; i < numDaysArray.length; i++) {
      const info = numDaysArray[i];
      const startDate =
        info.type === "reminder"
          ? moment().startOf("hour").add(info.numDays, "day").add(13, "hours").valueOf()
          : moment().startOf("hour").subtract(info.numDays, "day").add(13, "hours").valueOf();
      const endDate =
        info.type === "reminder"
          ? moment().endOf("hour").add(info.numDays, "day").add(13, "hours").valueOf()
          : moment().endOf("hour").subtract(info.numDays, "day").add(13, "hours").valueOf();

      const ineligibleRegistrationData = (
        await getAppPgPool().query(
          `select f.*, os.item ->> 'registrationDueDateMS' as registration_due_date_ms
          from f_player_bundle_registration_status(null, null) f,
               mirror_orgseason os
          where os.id = f.org_season_id
            and f.status != 'registered'
            and f.status != 'team-not-ready'
            and f.status != 'no-registration-needed'
            and cast(os.item ->> 'registrationDueDateMS' as numeric) > $1
            and cast(os.item ->> 'registrationDueDateMS' as numeric) <= $2;`,
          [startDate, endDate]
        )
      ).rows.map(row => {
        return {
          orgId: row["org_id"],
          orgSeasonId: row["org_season_id"],
          status: row["status"],
          playerBundleId: row["player_bundle_id"],
          prioritizedRegistrationPackageId: row["prioritized_registration_package_id"],
          registrationDueDateMS: parseInt(row["registration_due_date_ms"]),
          orgRegistration: row["org_registration"],
          type: info.type,
          numDays: info.type === "reminder" ? info.numDays : undefined
        } as IneligibilityReminderData;
      });
      olliePipe.emitEvent({
        type: "metric-send-ineligible-reminders",
        payload: { type: "group", info, ineligiblePlayerBundleIds: ineligibleRegistrationData.map(a => a.playerBundleId) }
      });

      allIneligibleData = [...allIneligibleData, ...ineligibleRegistrationData];
    }

    const weeklyPastDueData = (
      await getAppPgPool().query(`select f.*, os.item ->> 'registrationDueDateMS' as registration_due_date_ms
      from f_player_bundle_registration_status(null, null) f,
           mirror_orgseason os
      where os.id = f.org_season_id
      and coalesce(cast(os.item->>'archivedAtMS' as numeric), 0) = 0
        and f.status != 'registered'
        and f.status != 'team-not-ready'
        and f.status != 'no-registration-needed'
        and EXTRACT(EPOCH FROM to_timestamp(cast(os.item ->> 'startDateMS' as numeric) / 1000)) * 1000 < EXTRACT(EPOCH FROM NOW()) * 1000 -- season has started
        and EXTRACT(EPOCH FROM to_timestamp(cast(os.item ->> 'endDateMS' as numeric) / 1000)) * 1000 >= EXTRACT(EPOCH FROM NOW()) * 1000 -- season hasn't ended
        and EXTRACT(EPOCH FROM NOW()) * 1000 - (CAST(os.item ->> 'registrationDueDateMS' as bigint) + 36000000) >=
            604800000 -- 7 days in milliseconds. NOTE: We add 10 hours because the due dates are at midnight and we don't want to notify people at midnight. This notifies them at 10 am.
        AND CAST(EXTRACT(EPOCH FROM NOW()) * 1000 - (CAST(os.item ->> 'registrationDueDateMS' AS bigint) +
                    36000000) AS bigint) % 604800000
<= 3600000;`)
    ).rows.map(row => {
      return {
        orgId: row["org_id"],
        orgSeasonId: row["org_season_id"],
        status: row["status"],
        playerBundleId: row["player_bundle_id"],
        prioritizedRegistrationPackageId: row["prioritized_registration_package_id"],
        registrationDueDateMS: parseInt(row["registration_due_date_ms"]),
        orgRegistration: row["org_registration"],
        type: "pastDue"
      } as IneligibilityReminderData;
    });
    olliePipe.emitEvent({
      type: "metric-send-ineligible-reminders",
      payload: { type: "weekly-past-due", ineligiblePlayerBundleIds: weeklyPastDueData.map(a => a.playerBundleId) }
    });
    allIneligibleData = [...allIneligibleData, ...weeklyPastDueData];

    await triggerForIneligibleNotifications({
      ineligibleNotificationData: allIneligibleData
    });
  } catch (e: any) {
    console.log(e);
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-send-ineligible-reminders",
      payload: e,
      slackImportantErrorsMessage: `Error running send-ineligible-reminders. ${JSON.stringify(
        {
          message: e.message,
          stack: e.stack
        },
        null,
        2
      )}`
    });
  }
  // SERVER_ONLY_TOGGLE
}

orgRegistration__server__sendIneligibleReminders.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
